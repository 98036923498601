<template>
  <div class="login">
    <header-view></header-view>
    <div class="content">
      <div class="box">
        <!-- <div class="login-img">
          <img src="../../assets/images/login.png" alt="" />
        </div> -->
        <div class="title">修改密码</div>
        <div class="right">
          <el-form
            :model="loginForm"
            label-position="left"
            label-width="100px"
            :rules="loginRules"
            ref="loginForm"
          >
            <el-form-item prop="password" label="密码">
              <el-input
                placeholder="请输入密码"
                v-model="loginForm.password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="rePassword" label="确认密码">
              <el-input
                placeholder="请输入确认密码"
                v-model="loginForm.rePassword"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            :loading="loading"
            @click.native.prevent="handleLogin"
            class="btn"
            >确认修改</el-button
          >
        </div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { updatePass } from "@/api/user";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error("密码不能小于5位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        password: "",
        phone: "",
      },
      loginRules: {
        password: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        rePassword: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
      },
      loading: false,
    };
  },
  mounted() {
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          updatePass(this.loginForm)
            .then((res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
               this.$router.push("Login");
      localStorage.removeItem('GFtoken')
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .box {
    width: 100% !important;
  }
  .right {
    width: 100% !important;
    padding: 0 10px !important;
  }
}
.login {
  background: #f9f9f9;
}

.content {
  min-height: calc(100vh - 100px);
  .box {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding-top: 20px;
    box-sizing: border-box;
    min-height: calc(100vh - 100px);
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .title {
      color: #333;
      font-size: 26px;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      letter-spacing: 10px;
    }
    .right {
      width: 50%;
      flex-shrink: 0;
      padding: 0 50px;
      box-sizing: border-box;
      .codebtn {
        border: 1px solid #fe7b0a;
        height: 40px;
        border-radius: 4px;
        margin-left: 16px;
        color: #fe7b0a !important;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .zhuce {
      color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      width: 100%;
      height: 52px;
      background: #11aa8c;
      color: #fff;
      font-size: 20px;
      margin-top: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
